

















































































































































































































































































import { Component, Inject, InjectReactive, Prop, Watch } from "vue-property-decorator";
import { namespace } from "vuex-class";
import FscPageHeader from "@/components/FscPageHeader.vue";
import CreateButton from "@/components/Button/CreateButton.vue";
import EditButton from "@/components/Button/EditButton.vue";
import VerticalDivider from "@/components/VerticalDivider.vue";
import RemoveButton from "@/components/Button/RemoveButton.vue";
import UploadButton from "@/components/Button/UploadButton.vue";
import FileCsvButton from "@/components/Button/FileCsvButton.vue";
import PrintButton from "@/components/Button/PrintButton.vue";
import Actions from "@/components/Actions.vue";
import CopyButton from "@/components/Button/CopyButton.vue";
import ArchiveButton from "@/components/Button/ArchiveButton.vue";
import FilterButton from "@/components/Button/FilterButton.vue";
import FscCard from "@/components/Card/FscCard.vue";
import MailButton from "@/components/Button/MailButton.vue";
import InfoButton from "@/components/Button/InfoButton.vue";
import AbortButton from "@/components/Button/AbortButton.vue";
import SaveButton from "@/components/Button/SaveButton.vue";
import Table from "@/components/Table.vue";
import BookButton from "@/components/Button/BookButton.vue";
import { BModal } from "bootstrap-vue/src/components/modal";
import SendMessage from "@/views/Exam/PracticalExam/Participants/Edit/SendMessage.vue";
import Avatar from "@/components/Avatars/Avatar.vue";
import { IStudentExamFormDTO } from "@/interfaces/Exam/IStudentExamFormDTO";
import { Portions } from "@/constants/Portions";
import { mixins } from "vue-class-component";
import ModalMixin from "@/mixins/ModalMixin";
import DeleteModal from "@/components/Modal/DeleteModal.vue";
import FilterAndSearch from "@/components/FilterAndSearch.vue";
import PriceMixin from "@/mixins/PriceMixin";
import Info from "@/views/Exam/PracticalExam/Participants/Info.vue";
import FscSimpleCard from "@/components/Card/FscSimpleCard.vue";
import TheoryExamProtocolPreview from "@/views/Exam/TheoryExam/Participants/Edit/TheoryExamProtocolPreview.vue";
import FscModal from "@/components/Modal/FscModal.vue";
import ExamDocumentMixin from "@/mixins/ExamDocumentMixin.ts";
import ExamDocumentModal from "@/components/Exam/ExamDocumentModal.vue";
import { ExamDocumentTypeEnum } from "@/enums/ExamDocumentTypeEnum";
import SendMailButton from "@/components/Button/SendMailButton.vue";
import _ from "lodash";
import { formatStudentName } from "@/utils/NameUtil";
import Datepicker from "@/components/Datepicker.vue";
import moment from "moment";
import ProofOfTrainingDocumentPreview from "@/views/Student/Info/ProofOfTrainingDocumentPreview.vue";
import ActionButton from "@/components/Button/ActionButton.vue";

const ExamStatusModule = namespace("exam-status");
const ProofOfTrainingModule = namespace("proof-of-training");

@Component({
  components: {
    ActionButton,
    SendMailButton,
    Info,
    FilterAndSearch,
    Avatar,
    SendMessage,
    BookButton,
    SaveButton,
    AbortButton,
    InfoButton,
    MailButton,
    FscCard,
    FilterButton,
    ArchiveButton,
    CopyButton,
    Actions,
    PrintButton,
    FileCsvButton,
    UploadButton,
    RemoveButton,
    VerticalDivider,
    EditButton,
    CreateButton,
    FscPageHeader,
    Table,
    DeleteModal,
    FscSimpleCard,
    TheoryExamProtocolPreview,
    FscModal,
    ExamDocumentModal,
    Datepicker,
    ProofOfTrainingDocumentPreview,
  },
})
export default class PracticalExamParticipantsList extends mixins(ModalMixin, PriceMixin, ExamDocumentMixin) {
  public name = "PracticalExamParticipantsList";

  public modalId = "practical-exam-document-id";
  public previewModalId = "preview-practical-exam-document-id";

  private protocolPopover = "protocolPopoverPracticalExam";

  @Prop()
  private examId!: any;

  @Prop({ type: Boolean, default: () => false })
  public loading!: boolean;

  @Prop()
  public exam: any;

  // find practical exam
  @Inject("fetchPracticalExam")
  private findOneAction!: (examId: number) => Promise<void>;

  // update practical exam statuses
  @Inject("updatePracticalExamStatus")
  private updateExamStatusAction!: (practicalExamStatuses: Array<any>) => Promise<void>;

  @InjectReactive("updatePracticalExamStatusSuccess")
  private examStatusSuccess!: boolean;

  @InjectReactive("updatePracticalExamStatusLoading")
  private examStatusLoading!: boolean;

  // book practical exam
  @Inject("bookPracticalExam")
  private bookAction!: (practicalExamId: number) => Promise<void>;

  @InjectReactive("bookPracticalExamLoading")
  private bookingLoading!: boolean;

  @InjectReactive("bookPracticalExamSuccess")
  private bookingSuccess!: boolean;

  // upload protocol
  @Inject("uploadProtocolPracticalExam")
  private uploadProtocolAction!: (data: FormData) => Promise<void>;

  @InjectReactive("uploadProtocolPracticalExamLoading")
  private uploadProtocolLoading!: boolean;

  @InjectReactive("uploadProtocolPracticalExamSuccess")
  private uploadProtocolSuccess!: boolean;

  // practical exam education status info
  @Inject("educationStatusInfoPracticalExam")
  private getExamTheoryPracticeInfoAction!: (id: number) => Promise<void>;

  @InjectReactive("educationStatusInfoPracticalExamData")
  private getEducationStatusInfo: any;

  // participant
  @Inject("removeParticipantPracticalExam")
  private removeStudent!: (examId: number) => Promise<void>;

  @InjectReactive({ from: "removeParticipantPracticalSuccess", default: () => false })
  private removeStudentSuccess: any;

  // exam status
  @ExamStatusModule.Action("findAll")
  private examStatusFindAll: any;

  @ExamStatusModule.Getter("getDataList")
  private examStatuses: any;

  // proof of training
  @ProofOfTrainingModule.Action("create")
  public createProofOfTraining: any;

  // portions
  @Inject("getPracticalExamPortions")
  private findAllPortions!: (licenseClass: string) => Promise<void>;

  @InjectReactive({ from: "practicalExamPortions", default: () => [] })
  private portions: any;

  @InjectReactive("portionsPracticalExamLoading")
  private portionsPracticalExamLoading!: boolean;

  //
  @InjectReactive("filterPracticalExam")
  public filterPracticalExam!: string;

  @InjectReactive("archivePracticalExamLoading")
  private archivePracticalExamLoading!: boolean;

  public selectedRow: any = null;
  public mappedStudentsList: Array<any> = [];
  public studentFieldsCopy: any = [];

  private studentFields = [
    {
      key: "name",
      label: "",
      sortable: false,
    },
    {
      key: "time",
      label: this.$t("calendar.form_time"),
      sortable: false,
    },
    {
      key: "instructor.initials",
      label: this.$t("general.driving_instructor"),
      sortable: false,
    },
    {
      key: "attempts",
      label: this.$t("general.participation"),
      sortable: false,
    },
    {
      key: "licenseClass",
      label: this.$t("calendar.class"),
      sortable: false,
    },
    {
      key: "readyForPracticalExam",
      label: "§",
      sortable: false,
      class: "text-center",
    },
    {
      key: "saldo",
      label: this.$t("students.balance"),
      sortable: false,
    },
    {
      key: "examStatus",
      label: this.$t("general.status"),
      sortable: false,
    },
    {
      key: "preview",
      label: this.$t("general.proof"),
      sortable: false,
    },
    {
      key: "protocol",
      label: this.$t("general.protocol"),
      sortable: false,
    },
    {
      key: "protocolStudentDocumentId",
      label: "",
    },
  ];
  public reduceColumns = false;

  public studentDocumentId: any = null;

  protected examStudents: Array<any> = [];
  public currentPopoverId: any = null;
  public info = {
    studentEducationId: 0,
    requirementsToFulfillmentMap: [],
  };
  public issueDate = "";
  public studentEducationIdPreview: any = null;

  public get isShownPopover(): any {
    return (id: any) => this.currentPopoverId === id || false;
  }

  public get initialDate(): any {
    return moment().format("YYYY-MM-DD");
  }

  public fetchPracticalExam(examId: number): void {
    this.findOneAction(examId);
  }

  public mounted(): void {
    this.studentFieldsCopy = _.cloneDeep(this.studentFields);
    this.examStatusFindAll({ resource: "exams-statuses" });
    this.$root.$on("form-email-close", () => {
      this.reduceColumns = !this.reduceColumns;
      if (this.reduceColumns) {
        this.studentFields = this.studentFields.slice(0, 2);
      } else {
        this.studentFields = this.studentFieldsCopy;
      }
      this.$emit("reduce-columns", this.reduceColumns);
    });
  }

  private showBookExamQuestion(): void {
    if (!this.exam.studentPracticalExams || this.exam.studentPracticalExams.length === 0) {
      return;
    }
    this.$bvModal.show("book-modal");
  }

  public bookExamCancel() {
    this.$bvModal.hide("book-modal");
  }

  private hideSendMessage(): void {
    if (this.$refs["send-message"]) {
      (this.$refs["send-message"] as BModal).hide();
    }
  }

  private async bookExam(): Promise<void> {
    if (!this.exam.studentPracticalExams || this.exam.studentPracticalExams.length === 0) {
      return;
    }
    await this.bookAction(this.exam.id);

    if (this.bookingSuccess) {
      this.fetchPracticalExam(this.examId);
      this.$toasted.success(String(this.$t("messages.booking_success")));
    }
  }

  public openEmailForm() {
    this.mappedStudentsList = this.examStudents.map((item: any) => {
      return { ...item.student };
    });
    this.reduceColumns = !this.reduceColumns;
    if (this.reduceColumns) {
      this.studentFields = this.studentFields.slice(0, 2);
    } else {
      this.studentFields = this.studentFieldsCopy;
    }
    this.$emit("reduce-columns", this.reduceColumns);
    this.$emit("set-recipients", { allStudents: this.mappedStudentsList });
  }

  @Watch("exam", { deep: true, immediate: true })
  private async initExam(exam: any): Promise<void> {
    this.examStudents = [];
    if (!exam || !exam.studentPracticalExams || exam.id !== this.examId) return;

    let examStudents: Array<any> = [];
    for (const practicalExam of exam.studentPracticalExams) {
      let examStudent = {
        id: practicalExam.id,
        examPortions: practicalExam.examPortions,
        examStatus: { ...practicalExam.examStatus },
        licenseClass: practicalExam.licenseClass,
        attempts: practicalExam.attempts,
        time: practicalExam.time,
        instructor: practicalExam.instructor,
        student: practicalExam.student,
        slots: practicalExam.slots,
        balance: practicalExam.balance,
        portions: [],
        visiblePortion: false,
        disabled: true,
        hasPortions: false,
        _showDetails: false,
        studentEducationId: practicalExam.studentEducationId,
        readyForPracticalExam: practicalExam.readyForPracticalExam,
        protocolStudentDocumentId: practicalExam.protocolStudentDocumentId,
        proofOfTrainingCreated: practicalExam.proofOfTrainingCreated,
        proofOfTrainingSigned: practicalExam.proofOfTrainingSigned,
        studentHasBirthDay: practicalExam.studentHasBirthDay,
      };

      await this.findAllPortions(practicalExam.licenseClass);

      const portions: Array<any> = [];
      if (practicalExam.examPortions) {
        examStudent.hasPortions = true;
        for (const portion of practicalExam.examPortions) {
          portions.push({
            items: this.portions,
            examPortionId: portion.examPortion.id,
            examStatus: { ...portion.examStatus },
          });
        }
      } else if (this.portions) {
        for (const portion of this.portions) {
          portions.push({
            items: this.portions,
            examPortionId: portion.id,
            examStatus: { id: 1 },
          });
        }
      }
      // @ts-ignore
      examStudent.portions = portions;
      examStudents.push(examStudent);
    }

    this.examStudents = examStudents;
    this.reduceColumns = false;
    this.$emit("reduce-columns", false);
    this.$nextTick(() => {
      this.studentFields = this.studentFieldsCopy;
    });
  }

  @Watch("examStudents", { deep: true, immediate: true })
  private onStudentListChange(students: any) {
    this.mappedStudentsList = this.examStudents.map((item: any) => {
      return { ...item.student };
    });
    this.$nextTick(() => {
      this.studentFields = this.studentFieldsCopy;
      this.$emit("set-recipients", { allStudents: this.mappedStudentsList });
    });
  }

  private onUpload(studentPracticalExamId: number): void {
    const fileInput = this.$refs["protocol_" + studentPracticalExamId];
    if (fileInput) {
      (fileInput as HTMLFormElement).click();
    }
  }

  private async onFileChange(studentPracticalExamId: number): Promise<void> {
    const fileInput = this.$refs["protocol_" + studentPracticalExamId];
    if (!fileInput) {
      return;
    }
    const file = (fileInput as HTMLFormElement).files[0];
    if (file) {
      const formData = new FormData();
      formData.append("file", file);
      formData.append("studentPracticalExamId", studentPracticalExamId.toString());

      await this.uploadProtocolAction(formData);
      if (this.uploadProtocolSuccess) {
        this.$toasted.success(String(this.$t("messages.upload_success")));
        this.fetchPracticalExam(this.examId);
      }
    }
  }

  private async onSubmit(): Promise<void> {
    if (!this.examStudents) return;

    const studentExamFormDTOs: Array<IStudentExamFormDTO> = [];
    for (const studentExam of this.examStudents) {
      const portions =
        studentExam.hasPortions || (studentExam.hasPortions === false && studentExam._showDetails)
          ? studentExam.portions.map((x: any) => {
              return {
                examPortion: {
                  id: x.examPortionId,
                },
                examStatus: x.examStatus,
              };
            })
          : [];

      let preparedStudent: any = {
        examStatusId: studentExam.examStatus.id,
        examPortions: portions,
        studentPracticalExamId: studentExam.id,
      };

      studentExamFormDTOs.push(preparedStudent);
    }

    const exam = [...studentExamFormDTOs];

    await this.updateExamStatusAction(exam);

    if (this.examStatusSuccess) {
      this.$toasted.success(String(this.$t("messages.save_success")));
    }
  }

  private onPopoverClose() {
    this.currentPopoverId = null;
  }

  private async getEducationInfo(row: any) {
    const { id, studentEducationId } = row;
    this.currentPopoverId = null;
    await this.getExamTheoryPracticeInfoAction(studentEducationId);
    this.info = this.getEducationStatusInfo;
    this.currentPopoverId = id;
  }

  private onRowClicked(ctx: any): void {
    this.selectedRow = ctx;
  }

  private showDeleteModal(): void {
    if (!this.selectedRow) {
      return;
    }
    this.showModal("delete-modal");
  }

  private async onDelete(): Promise<void> {
    await this.removeStudent(this.selectedRow?.id);
    if (this.removeStudentSuccess) {
      this.$emit("on-student-remove");
    }
  }

  private onClose(): void {
    this.$emit("on-close");
  }

  private onEdit(): void {
    this.reduceColumns = false;
    this.$emit("reduce-columns", false);
    this.$emit("on-edit");
  }

  private fullName(item: any): string {
    return formatStudentName(item.firstName, item.lastName);
  }

  private activeEducations(item: any): string {
    return item.activeEducations?.join(" | ");
  }

  private getExamPortion(portions: Array<any>, portionId: number): any {
    return portions.find((x) => x.id === portionId)?.name;
  }

  private get isBooked(): boolean {
    return this.exam.booked;
  }

  private get portionTypes(): Array<any> {
    return Portions;
  }

  private visibleSplitByLicenseClass(item: any): boolean {
    const { licenseClass } = item;
    return this.portionTypes.includes(licenseClass);
  }

  private protocolDocumentPreview(documentId: any) {
    if (documentId) {
      this.studentDocumentId = documentId;
      this.$bvModal.show(this.protocolPopover);
    }
    return;
  }

  private onPrintTheoryDocument() {
    if (this.examId) {
      this.$bvModal.show(this.modalId);
    }
  }

  private get documentTypeId() {
    return ExamDocumentTypeEnum.PRACTICAL;
  }

  private onPreviewNachwaisModal(item: any) {
    this.issueDate = this.initialDate;
    this.studentEducationIdPreview = item.studentEducationId ? item.studentEducationId : null;
    this.$bvModal.show("date-select-modal");
  }

  private onPreviewNachwaisDocument() {
    this.$bvModal.show("preview-document-modal-id");
  }

  private async onNachwaisDocumentSubmit(): Promise<any> {
    this.$bvModal.hide("preview-document-modal-id");
    const id = this.studentEducationIdPreview;
    const date = this.issueDate;
    await this.createProofOfTraining({
      resource: `proof-of-training?studentEducationId=${id}&issueDate=${date}`,
    });
    this.issueDate = "";
    this.fetchPracticalExam(this.examId);
  }

  private onNachwaisDocumentCancel() {
    this.$bvModal.hide("preview-document-modal-id");
    this.issueDate = "";
  }

  private hasCreatedProofOfTraining(item: any) {
    return item?.proofOfTrainingCreated || false;
  }

  private hasSignedProofOfTraining(item: any) {
    return item?.proofOfTrainingSigned || false;
  }

  private isExamSuccessful(item: any) {
    if (item.examStatus && item.examStatus.id == 3) {
      return false;
    }
    return true;
  }
  private async openBroadcastForm() {
    if (!this.exam) return;
    const practicalExamId = this.exam.id;
    await this.$router.push({ name: "Broadcast", query: { newForm: "true" } });
    this.$root.$emit("broadcast-selection-change", { formIsNew: true, entryId: practicalExamId, type: "practicalExam" });
  }

  private get isLoading() {
    return this.portionsPracticalExamLoading || this.loading || this.bookingLoading || this.examStatusLoading || this.archivePracticalExamLoading;
  }
}
