




























import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { Validate } from "vuelidate-property-decorators";
import { required } from "vuelidate/lib/validators";
import { BModal } from "bootstrap-vue/src/components/modal";
import Validation from "@/components/Validation.vue";

@Component({
  components: { Validation },
})
export default class SendMessage extends Vue {
  public name = "SendMessage";

  @Prop()
  public email!: string;

  @Validate({ required })
  private vEmail?: string | null = null;

  @Validate({ required })
  private note?: string | null = null;

  @Watch("email", { deep: true, immediate: true })
  private onEmailChange(email: any): void {
    if (email) {
      this.vEmail = email;
    }
  }

  private sendMessage(): void {
    this.close();
  }

  public show(): any {
    if (this.$refs["send-message"]) {
      (this.$refs["send-message"] as BModal).show();
    }
  }

  private close(): any {
    this.$emit("on-close");
  }
}
