











































import { Component, InjectReactive, Prop, Watch } from "vue-property-decorator";
import { mixins } from "vue-class-component";
import { IStudentPracticalExam } from "@/interfaces/Exam/IStudentPracticalExam";
import Table from "@/components/Table.vue";
import TimePicker from "@/components/TimePicker.vue";
import Avatar from "@/components/Avatars/Avatar.vue";
import StudentListRequestMixin from "@/mixins/Request/StudentListRequestMixin";
import { formatStudentName } from "@/utils/NameUtil";

@Component({
  methods: { formatStudentName },
  components: { Avatar, TimePicker, Table },
})
export default class Students extends mixins(StudentListRequestMixin) {
  public name = "Students";

  @Prop({ type: String })
  public filtered!: any;

  @InjectReactive({ from: "archivePracticalExamLoading", default: () => false })
  private archivePracticalExamLoading!: boolean;

  @InjectReactive({ from: "archiveTheoryExamLoading", default: () => false })
  private archiveTheoryExamLoading!: boolean;

  @InjectReactive({ from: "createDeleteOrUpdateLoadingTheoryExam", default: () => false })
  private createDeleteOrUpdateLoadingTheoryExam!: boolean;

  @InjectReactive({ from: "createDeleteOrUpdateLoadingPracticalExam", default: () => false })
  private createDeleteOrUpdateLoadingPracticalExam!: boolean;

  private studentFields = [
    {
      key: "name",
      label: "",
      sortable: false,
    },
  ];

  public requestStudentsQuery(query: Record<any, any>): void {
    this.fetchStudentList(query);
  }

  private onRowDoubleClicked(student: IStudentPracticalExam): void {
    this.$emit("row-dblclicked", student);
  }

  @Watch("filtered")
  public onSearch(term: string) {
    (this.$refs.fscTable as Table).onSearch(term);
  }

  private get isLoading() {
    return (
      this.studentListLoading ||
      this.archivePracticalExamLoading ||
      this.archiveTheoryExamLoading ||
      this.createDeleteOrUpdateLoadingTheoryExam ||
      this.createDeleteOrUpdateLoadingPracticalExam
    );
  }
}
