







































































































import { Component, InjectReactive, Prop } from "vue-property-decorator";
import { mixins } from "vue-class-component";
import ViewSwitchMixin from "@/mixins/ViewSwitchMixin";
import { IStudentPracticalExam } from "@/interfaces/Exam/IStudentPracticalExam";
import Table from "@/components/Table.vue";
import TimePicker from "@/components/TimePicker.vue";
import Avatar from "@/components/Avatars/Avatar.vue";
import { formatStudentName } from "@/utils/NameUtil";
import { PropType } from "vue";
import { IInstructor } from "@/interfaces/IInstructor";

@Component({
  components: { Avatar, TimePicker, Table },
})
export default class PracticalExamStudents extends mixins(ViewSwitchMixin) {
  public name = "PracticalExamStudents";

  @Prop()
  public exam!: any;

  @Prop()
  public examStudents!: any;

  @Prop()
  public vehicles!: any;

  @Prop({ type: Function, required: true })
  public visibleSplitByLicenseClass!: any;

  @Prop({ type: Array as PropType<Array<IInstructor>>, default: () => [] })
  public instructors!: Array<IInstructor>;

  @Prop({ type: Boolean, default: () => false })
  public instructorsLoading!: boolean;

  public examStudentFields = [
    {
      key: "name",
      label: "",
      sortable: true,
    },
    {
      key: "time",
      label: this.$t("calendar.form_time"),
      sortable: true,
    },
    {
      key: "licenseClass",
      label: this.$t("calendar.class"),
      sortable: true,
    },
    {
      key: "instructor",
      label: this.$t("general.driving_instructor"),
      sortable: false,
    },
    {
      key: "vehicles",
      label: this.$t("calendar.vehicle"),
      sortable: false,
    },
    {
      key: "actions",
      label: "",
      sortable: false,
    },
  ];

  private onRowClicked(student: IStudentPracticalExam): void {
    this.$emit("row-clicked", student);
  }

  private onRowDoubleClicked(student: IStudentPracticalExam): void {
    this.$emit("row-dblclicked", student);
  }

  private toggleSubLicenseClassExams(studentPracticalExam: IStudentPracticalExam): void {
    this.$emit("toggle-sub-license-class-exams", studentPracticalExam);
  }

  private onLicenseClassChange(student: IStudentPracticalExam): void {
    this.$emit("on-license-class-change", student);
  }

  protected disableSelection(row: any): boolean {
    return row.item.disabled;
  }

  protected formatName(item: any): string {
    return formatStudentName(item.student.firstName, item.student.lastName);
  }

  protected formatActiveEducations(item: any): string {
    return item.student.activeEducations?.join(" | ");
  }

  protected get isBooked(): boolean {
    return this.exam.booked;
  }

  @InjectReactive("filterPracticalExam")
  public filterPracticalExam!: string;
}
