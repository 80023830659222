







import { Component, Prop, Vue } from "vue-property-decorator";
import PracticalExamCreate from "@/views/Exam/PracticalExam/Exam/PracticalExamCreate.vue";
import PracticalExamUpdate from "@/views/Exam/PracticalExam/Exam/PracticalExamUpdate.vue";
import { PropType } from "vue";
import { ExamModeEnum } from "@/enums/ExamModeEnum";

@Component({
  components: {
    PracticalExamUpdate,
    PracticalExamCreate,
  },
})
export default class PracticalExamInfo extends Vue {
  public name = "PracticalExamInfo";

  @Prop({ required: true, type: Number as PropType<ExamModeEnum> })
  public examMode!: ExamModeEnum;

  @Prop()
  public exam!: any;

  @Prop()
  public loading!: any;

  private onClose(): void {
    this.$emit("on-close");
  }

  private get editMode() {
    return this.examMode === ExamModeEnum.edit;
  }
}
