




import { Component, Inject, Prop, Vue } from "vue-property-decorator";
import PracticalExamForm from "@/views/Exam/PracticalExam/Exam/PracticalExamForm.vue";

@Component({
  components: {
    PracticalExamForm,
  },
})
export default class PracticalExamCreate extends Vue {
  public name = "PracticalExamCreate";

  @Prop()
  public loading: any;

  @Inject("createPracticalExam")
  protected createPracticalExam: any;

  private onSubmit(exam: any): void {
    this.createPracticalExam(exam);
  }

  private onClose(): void {
    this.$emit("on-close");
  }
}
